import { createSlice } from "@reduxjs/toolkit";

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    currentSubscription: null,
  },
  reducers: {
    setSubscription: (state, action) => {
      state.currentSubscription = action.payload;
    },
  },
});

export const { setSubscription } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
