import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { stripeApi } from "../utils/stripeApi";
import { LoaderCircle, CircleCheck, TriangleAlert } from "lucide-react";
import { useNavigate } from "react-router-dom";

const CheckoutForm = ({ product, price }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const buttons = {
    primary: { color: "#3b82f6", text: "Pay Now", icon: null },
    success: { color: "#016917", text: "Succesfull", icon: <CircleCheck /> },
    loading: {
      color: "#5475aa",
      text: "Processing",
      icon: <LoaderCircle className="animate-spin" />,
    },
    error: { color: "#bb4a4a", text: "Error", icon: <TriangleAlert /> },
  };
  const [buttonState, setButtonState] = useState(buttons.primary);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setButtonState(buttons.loading);
    try {
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("card"),
      });

      const res = await stripeApi.post("/subscribe", {
        email,
        name,
        description : address,
        paymentMethod: paymentMethod.paymentMethod.id,
        price,
      });

      if (res.status !== 200) {
        return alert("Payment unsuccessful");
      }

      const confirm = await stripe.confirmCardPayment(res.data.clientSecret);
      if (confirm.error) {
        return alert("Payment unsuccessful");
      }
    } catch (err) {
      console.error(err);
      setButtonState(buttons.error);
      alert("Payment Failed: " + err.message);
      return;
    }
    setLoading(false);
    setButtonState(buttons.success);
    setTimeout(() => navigate("/completion"), 500);
  };

  return (
    <div className="flex flex-col md:flex-row bg-[#f8f8f8] shadow rounded-lg">
      <div className="md:w-1/2 p-8 ">
        <img
          src={product.image}
          alt={product.name}
          className="w-full h-auto aspect-square object-cover mb-6 rounded-lg"
        />
        <h3 className="text-2xl font-bold mb-4">{product.name}</h3>
        <p className="text-gray-700 mb-4">{product.description}</p>
        <p className="bg-black text-white px-4 py-2 rounded-full w-fit shadow-md">
          Price: ${price}
        </p>
      </div>
      <div className="h-[90%] w-[1px] bg-[#dfdede] my-auto shadow-sm" />
      <div className="md:w-1/2 p-8">
        <h2 className="text-2xl font-bold mb-6">Payment Details</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="your@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="address"
            >
              Shipping address
            </label>
            <textarea
              className="shadow h-[70px] resize-none appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="address"
              type="text"
              placeholder="For ex - 123 Main St, Manhattan, NewYork"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>

          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="card-element"
            >
              Credit or debit card
            </label>
            <CardElement
              id="card-element"
              className="border border-gray-300 p-3 rounded"
            />
          </div>

          <button
            type="submit"
            disabled={!stripe || loading}
            style={{ backgroundColor: buttonState.color }}
            className={`flex items-center justify-center gap-2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full`}
          >
            {buttonState.icon}
            {buttonState.text}
          </button>
          <p className="mt-4 text-gray-600 text-[0.7rem] ">
            Thank you for your purchase. Your support helps us provide quality
            services to our customers.
          </p>
        </form>
      </div>
    </div>
  );
};

export default CheckoutForm;
