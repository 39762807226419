import axios from "axios";

const instance = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "http://localhost:3001" : "https://pookie-stripe.onrender.com/",
  headers: {
    "Content-type": "application/json",
    Authorization : `Bearer ${process.env.REACT_APP_STRIPE_SERVER_ACCESS_TOKEN}`
  },
});

export const stripeApi = instance;
