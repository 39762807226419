import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { fetchUser } from "../redux/slice/Profile";
import { api } from "../utils/api";
import { LoaderCircle } from "lucide-react";

import { logAnalyticsEvent } from "../utils/analytics";
import { track } from "../utils/traking";

function ShakingButton({ sentFrom, username }) {
  const [isShaking, setIsShaking] = useState(false);
  function storeAnalytics() {
    let param;
    switch (sentFrom) {
      case "any":
        param = { from_page_type: "anonymous messages" };
        break;
      case "conf":
        param = { from_page_type: "confession page" };
        break;
      case "tbh":
        param = { from_page_type: "honest reviews" };
        break;
      case "dond":
        param = { from_page_type: "deal or no deal" };
        break;
      case "abtme":
        param = { from_page_type: "about me" };
        break;
      case "wsp":
        param = { from_page_type: "whisper secret" };
        break;
      default:
        return;
    }

    logAnalyticsEvent("analytics/download_button_click", param);
    track("tracking/download_button_click", param);
  }
  useEffect(() => {
    const shakeInterval = setInterval(() => {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 820);
    }, 1820);
    return () => clearInterval(shakeInterval);
  }, []);

  return (
    <button
      className={`bg-black text-white mt-5 font-semibold md:text-xl text-base self-stretch w-full py-5 rounded-full ${
        isShaking ? "animate-shake" : ""
      }`}
    >
      <a
        href="https://apps.apple.com/us/app/pookie-meme-your-pookie-daily/id6466747851"
        target="_blank"
        onClick={storeAnalytics}
      >
        Get your own Pookie names
      </a>
    </button>
  );
}

function UserCounter({ username, sentFrom }) {
  const [count, setCount] = useState(Math.floor(Math.random() * 200) + 100);
  const [phase, setPhase] = useState("initial");

  useEffect(() => {
    const interval = setInterval(() => {
      let increment;
      if (phase === "initial") {
        increment = Math.random() < 0.4 ? -1 : Math.floor(Math.random() * 3);
      } else {
        increment = Math.floor(Math.random() * 3) + 1;
      }

      setCount((prevCount) => prevCount + increment);
    }, 1000);

    const phaseTimeout = setTimeout(() => {
      setPhase("incremental");
    }, 10000);

    return () => {
      clearInterval(interval);
      clearTimeout(phaseTimeout);
    };
  }, [phase]);

  return (
    <div className="w-full max-w-3xl flex flex-col mb-6">
      <p className="text-center font-bold">
        👇{count} people just tapped the button👇
      </p>
      <ShakingButton username={username} sentFrom={sentFrom} />
      <div className="self-center flex gap-2 mt-2  text-[#00000086] text-xs ">
        <Link className="font-bold" to="/terms">
          Terms
        </Link>
        <Link className="font-bold" to="/privacy">
          Privacy
        </Link>
      </div>
    </div>
  );
}

function InputForm({
  id,
  userid,
  sentFrom,
  message_type,
  placeholder,
  sent_link,
  setIsDisplaying,
  isDisplaying,
}) {
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [analytictsSent, setAnalyticsSent] = useState(false);
  const [cb, setCb] = useState(null);

  useEffect(() => {
    if (message && !analytictsSent) {
      logAnalyticsEvent("analytics/user_entered_message", {
        from_page_type: sentFrom,
        username: id,
      });
      track("tracking/user_entered_message", {
        from_page_type: sentFrom,
        username: id,
      });
      setAnalyticsSent(true);
    }
  }, [message]);

  function storeAnalytics() {
    switch (sentFrom) {
      case "any":
        logAnalyticsEvent("analytics/message_sent_anonymouse_message", {
          username: id,
        });
        track("tracking/message_sent_anonymouse_message", {
          username: id,
        });
        break;
      case "conf":
        logAnalyticsEvent("analytics/message_sent_confession", {
          username: id,
        });
        track("tracking/message_sent_confession", {
          username: id,
        });
        break;
      case "tbh":
        logAnalyticsEvent("analytics/message_sent_honest_review", {
          username: id,
        });
        track("tracking/message_sent_honest_review", {
          username: id,
        });
        break;
      case "dond":
        logAnalyticsEvent("analytics/message_sent_deal_or_no_deal", {
          username: id,
        });
        track("tracking/message_sent_deal_or_no_deal", {
          username: id,
        });
        break;
      case "abtme":
        logAnalyticsEvent("analytics/message_sent_about_me", { username: id });
        track("tracking/message_sent_about_me", { username: id });
        break;
      case "wsp":
        logAnalyticsEvent("analytics/message_sent_whisper_secret", {
          username: id,
        });
        track("tracking/message_sent_whisper_secret", {
          username: id,
        });
        break;
      default:
        return;
    }
  }

  async function formSubmit(e) {
    e.preventDefault();
    setLoading(true);

    setCb((p) => async () => {
      console.log("sending message now");
      if (!message) return;
      let id;
      try {
        const payload = {
          userID: userid,
          friendUserName: inputRef.current.value,
          sentFrom: sentFrom,
        };
        const res = await api.post("/userfriend/store-user-friends", payload);
        id = res.data.chatData.id;
        setLoading(false);
        storeAnalytics();
        navigate(sent_link.replace(/\:id/g, id));

      } catch (err) {
        alert("could send message to the user");
        return;
      }
    });
  }

  useEffect(() => {
    if (userid && cb){
      cb();
    }
  }, [userid, cb])

  return (
    <form
      onSubmit={formSubmit}
      className="flex flex-col items-center mt-8 w-full max-w-3xl"
    >
      <div className="relative rounded-3xl overflow-hidden w-full flex flex-col ">
        <div className="bg-white px-6 py-2 text-center flex gap-2 text-black">
          <div className="flex flex-col ">
            <p className="text-start text-sm">@{id}</p>
            <p className="font-bold">{message_type}</p>
          </div>
        </div>
        <textarea
          onBlur={() => setIsDisplaying(true)}
          ref={inputRef}
          placeholder={placeholder}
          value={message}
          onChange={(e) => {
            if (isDisplaying) {
              setIsDisplaying(false);
            }
            setMessage(e.target.value);
          }}
          className="bg-[#ffffff50] resize-none outline-none text-xl p-4 text-start h-[150px] placeholder:text-[#00000050] text-[#00000080] placeholder:font-bold md:placeholder:text-xl"
        ></textarea>
      </div>
      <span className="text-sm mt-2">🔒 anonymous q&a</span>
      <button
        type="submit"
        disabled={!Boolean(message)}
        style={{ display: message ? "block" : "none" }}
        className="bg-black md:text-xl text-base flex items-center justify-center px-auto gap-2 text-white mt-2 font-semibold self-stretch py-5 rounded-full"
      >
        {loading ? <LoaderCircle className="mx-auto animate-spin" /> : "Send!"}
      </button>
    </form>
  );
}

function NGL({
  color1,
  color2,
  message_type,
  placeholder,
  sentFrom,
  sent_link,
}) {
  const { id: username } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.style.background = `${color1}`;
    document.documentElement.style.backgroundRepeat = `no-repeat`;
  }, [color1, color2]);
  const [isDisplaying, setIsDisplaying] = useState(true);
  const dispatch = useDispatch();
  const {
    data,
    error,
    loading: userLoading,
  } = useSelector((state) => state.profileSlice);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(fetchUser({ userName: username }, { signal }));

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    let page_type;
    switch (sentFrom) {
      case "any":
        page_type = "anonymous messages";
        break;
      case "conf":
        page_type = "confession page";
        break;
      case "tbh":
        page_type = "honest reviews";
        break;
      case "dond":
        page_type = "deal or no deal";
        break;
      case "abtme":
        page_type = "about me";
        break;
      case "wsp":
        page_type = "whisper secret";
        break;
      default:
        return;
    }
    logAnalyticsEvent("analytics/page_view", {
      from_page_type: page_type,
      username,
    });
    track("tracking/page_view", {
      from_page_type: page_type,
      username,
    });
  }, [pathname]);

  return (
    <main
      style={{
        background: `linear-gradient(to bottom right, ${color1}, ${color2})`,
      }}
      className="h-screen flex flex-col items-center  px-7"
    >
      <InputForm
        id={username}
        userid={data.id}
        sentFrom={sentFrom}
        message_type={message_type}
        placeholder={placeholder}
        sent_link={sent_link}
        setIsDisplaying={setIsDisplaying}
        isDisplaying={isDisplaying}
      />
      <div
        className="my-auto"
        style={{ display: isDisplaying ? "block" : "none" }}
      >
        <UserCounter username={username} sentFrom={sentFrom} />
      </div>
    </main>
  );
}

export default NGL;
