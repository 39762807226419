import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";
import axios from "axios";


export const fetchProfile = createAsyncThunk(
  "fetchProfile",
  async ({ userName }, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await api.post("/user/user-details-by-userName", {
        userName,
      });

      if (!response.data.user) {
        throw new Error("user not found");
      }
      console.log(response.data.user)
      const id = response.data.user.id;
      const followersCount = 0, followingCount = 0;
      return { data: response.data.user, followersCount, followingCount, id };
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("request was cancelled");
      } else {
        throw new Error(error?.message);
      }
    }
  }
);

export const fetchUser = createAsyncThunk("fetchUser", async ({ userName }) => {
  try {
    const response = await api.post("/user/user-details-by-userName", {
      userName,
    });
    if (!response.data.user) {
      throw new Error("user not found");
    }
    return response.data.user;
  } catch (error) {
    if (axios.isCancel(error)) {
      throw new Error("request was cancelled");
    } else {
      throw new Error(error?.message);
    }
  }
});

const profileSlice = createSlice({
  name: "profileSlice",
  initialState: {
    loading: false,
    error: "",
    data: {
      profile: "",
      username: "",
      name: "",
      id : ""
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfile.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchProfile.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.error = "";
      state.loading = false;
      state.data.profile = action.payload.data.userProfile;
      state.data.name =
        action.payload.data.firstName + " " + action.payload.data.lastName;
      state.data.username = action.payload.data.userName;
      state.data.follower = action.payload.followersCount;
      state.data.following = action.payload.followingCount;
      state.data.id = action.payload.id;
    });

    builder.addCase(fetchUser.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.data.profile = action.payload.userProfile;
      state.data.name =
        action.payload.firstName + " " + action.payload.lastName;
      state.data.username = action.payload.userName;
      state.data.id = action.payload.id;
    });
  },
});

export default profileSlice.reducer;
