import React, { useEffect, useRef, useState } from 'react';

const ReasonCard = ({ reason, index }) => {
  const cardRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <div 
      ref={cardRef}
      className={`bg-white p-6 rounded-lg shadow-md border border-gray-200 transition-all duration-500 ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
    >
      <div className="flex items-center mb-4">
        <reason.icon className="w-6 h-6 mr-2 text-purple" />
        <h3 className="text-xl font-semibold text-black">{reason.title}</h3>
      </div>
      <p className="text-gray-600">{reason.description}</p>
    </div>
  );
};

export default ReasonCard;
