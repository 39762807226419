import { Route, Routes } from "react-router-dom";
import React from "react";
import Feed from "./page/Feed";
import Home from "./page/Home";
import Profile from "./page/Profile";
import NGL from "./page/NGL";
import Order from "./page/Order";
import CompletionPage from "./page/CompletionPage";
import Product from "./page/Product";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchUserInfo } from "./redux/slice/Auth";
import Terms from "./page/Terms";
import PrivacyPolicy from "./page/PrivacyPolicy";
import NGLSent from "./page/NGLSent";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  const data = [
    {
      id: 1,
      title: "any",
      desc: "Who should be my pookie?",
      placeholder: "Send names or insta-handles anonymously",
      colors: ["#8C52FF", "#5CE1E6"],
      link: "/any/:id",
      sent_link: "/any/p/:id",
    },
    {
      id: 2,
      title: "conf",
      desc: "Send me anonymous confessions!!",
      placeholder: "send me anomymous confession...",
      colors: ["#40C9FF", "#E81CFF"],
      link: "/conf/:id",
      sent_link: "/conf/p/:id",
    },
    {
      id: 3,
      title: "tbh",
      desc: "Truthful reviews!!",
      placeholder: "send your truthfull reviews...",
      colors: ["#F99D2E", "#A5FF5B"],
      link: "/tbh/:id",
      sent_link: "/tbh/p/:id",
    },
    {
      id: 4,
      title: "dond",
      desc: "Share your dealbreakers!!",
      placeholder: "Share your dealbreakers...",
      colors: ["#0061FF", "#60EFFF"],
      link: "/dond/:id",
      sent_link: "/dond/p/:id",
    },
    {
      id: 5,
      title: "abtme",
      desc: "Express yourself anonymously!!",
      placeholder: "tell me something about yourself...",
      colors: ["#FCC44B", "#F44C7D"],
      link: "/abtme/:id",
      sent_link: "/abtme/p/:id",
    },
    {
      id: 6,
      title: "wsp",
      desc: "Drop your secrets here!!",
      placeholder: "tell me something about your secrets here...",
      colors: ["#FCB0F3", "#3D05DD"],
      link: "/wsp/:id",
      sent_link: "/wsp/p/:id",
    },
  ];

  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/feed/:id" element={<Feed />} />
        <Route path="/profile/:id" element={<Profile />} />
        {data.map((item) => (
          <Route
            key={item.id}
            path={item.link}
            element={
              <NGL
                color1={item.colors[0]}
                color2={item.colors[1]}
                message_type={item.desc}
                placeholder={item.placeholder}
                sentFrom={item.title}
                sent_link={item.sent_link}
              />
            }
          />
        ))}
        {data.map((item) => (
          <Route
            key={item.id}
            path={item.sent_link}
            element={
              <NGLSent
                color1={item.colors[0]}
                color2={item.colors[1]}
                sentFrom={item.title}
              />
            }
          />
        ))}
        <Route path="/orders" element={<Order />} />
        <Route path="/completion" element={<CompletionPage />} />
        <Route path="/product" element={<Product />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
