import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";
import axios from "axios";

export const fetchFeed = createAsyncThunk(
  "fetchFeed",
  async ({ feedID }, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const URLmap = (
        await axios.get(
          "https://short-url-pookie.s3.eu-north-1.amazonaws.com/urlMap.json"
        )
      ).data;

      const id = URLmap[feedID];
      const response = await api.post("/feed/get-feed-details-by-id", {
        feedID: id,
      });
      return response.data;
    } catch (error) {
      console.log(error?.response?.status);
      if (axios.isCancel(error)) {
        throw new Error("request was cancelled");
      }
      if (error?.response?.status === 404 || error?.response?.status === 422) {
        throw new Error("Feed is not available :(");
      }
      if (error?.response?.status === 500) {
        throw new Error("we are out of service :(");
      }
    }
  }
);

const feedSlice = createSlice({
  name: "feedSlice",
  initialState: {
    loading: false,
    error: "",
    data: [],
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeed.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchFeed.rejected, (state, action) => {
      console.log(action.error.message);
      state.error = action.error.message;
      state.loading = false;
    });
    builder.addCase(fetchFeed.fulfilled, (state, action) => {
      state.error = "";
      state.loading = false;
      state.data = action.payload.feed;
    });
  },
});

export default feedSlice.reducer;

// 43e30c43-fbae-464d-b0fe-8c8964b7f59d
// 18fd5700-7f36-4eba-b807-6da3de17c6c5
//ed57f9d8-b58b-4232-9ee3-d3d1d1053ed2
