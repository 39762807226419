import React from "react";

const Terms = () => {
  return (
    <div className="bg-white min-h-[100dvh] overflow-auto">
      <div className="container mx-auto px-4 py-8">
        <div className="mx-auto">
          <h1 className="text-3xl flex gap-4 items-center font-bold text-black mb-6">
            <img src="images/Meta/logo.png" className="size-12 rounded-lg" />
            Terms and condition
          </h1>
          <p className="text-base text-black mb-2.5 leading-6">
            Welcome to Pookie! These Terms and Conditions ("Terms") govern your
            use of the Pookie app (the "App") provided by Harshit Foundation
            ("we," "us," or "our"). By using the App, you agree to these Terms.
            If you do not agree with these Terms, please do not use the App.
          </p>
          <h2 className="text-xl font-bold text-black my-2.5">
            1. Acceptance of Terms
          </h2>
          <p className="text-base text-black mb-2.5 leading-6">
            By accessing or using Pookie, you agree to comply with and be bound
            by these Terms. We reserve the right to modify these Terms at any
            time, and such modifications will be effective immediately upon
            posting. Your continued use of the App after changes are posted
            constitutes your acceptance of the revised Terms.
          </p>
          <h2 className="text-xl font-bold text-black my-2.5">
            2. User Accounts
          </h2>
          <p className="text-base text-black mb-2.5 leading-6">
            To use Pookie, you must create an account and provide accurate
            information, including your name, phone number, and email address.
            You are responsible for maintaining the confidentiality of your
            account information and for all activities that occur under your
            account.
          </p>
          <h2 className="text-xl font-bold text-black my-2.5">3. Content</h2>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Automated Content:</strong> The App uses artificial
            intelligence (AI) to generate and manage video content. Users do not
            have the capability to upload or create content directly. Content is
            automatically curated and presented for user interaction.
          </p>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Content Approval:</strong> You interact with content by
            swiping left or right to approve or reject videos. Your actions help
            refine the content recommendations.
          </p>
          <h2 className="text-xl font-bold text-black my-2.5">
            4. User Interaction
          </h2>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Swiping and Approval:</strong> The App allows you to swipe
            and approve videos about your friends. These interactions are used
            to personalize content and improve the user experience.
          </p>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Reporting Issues:</strong> If you encounter any issues or
            have concerns about the content, please use the reporting features
            available in the App. We reserve the right to review and address
            reported issues as needed.
          </p>
          <h2 className="text-xl font-bold text-black my-2.5">
            5. Data Privacy
          </h2>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Data Collection:</strong> We collect and use your personal
            information (name, phone number, and email) for account management
            and to provide you with a personalized experience. This information
            is necessary for us to operate the App and interact with you
            effectively.
          </p>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Data Protection:</strong> We implement reasonable security
            measures to protect your information. However, no system can be
            entirely secure, and we cannot guarantee absolute security.
          </p>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Data Sharing:</strong> We do not share your personal
            information with third parties, except as required by law or with
            trusted service providers who assist us in operating the App.
          </p>
          <h2 className="text-xl font-bold text-black my-2.5">
            6. Intellectual Property
          </h2>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Ownership:</strong> All content and materials provided in
            the App are owned by Harshit Foundation or licensed to us. You may
            not use, reproduce, or distribute any content from the App without
            our prior written consent.
          </p>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>User Rights:</strong> You have no ownership rights to the
            AI-generated content or other materials provided through the App.
          </p>
          <h2 className="text-xl font-bold text-black my-2.5">
            7. Monetization
          </h2>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>In-App Purchases and Ads:</strong> The App may include
            in-app purchases or advertisements. All transactions and
            interactions with third-party advertisers are subject to their terms
            and conditions.
          </p>
          <h2 className="text-xl font-bold text-black my-2.5">
            8. User Responsibilities
          </h2>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Behavior:</strong> You agree to use the App responsibly and
            in accordance with these Terms. You are prohibited from engaging in
            any conduct that disrupts or interferes with the App's operation.
          </p>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Account Termination:</strong> We may suspend or terminate
            your account if you violate these Terms or engage in unlawful
            behavior.
          </p>
          <h2 className="text-xl font-bold text-black my-2.5">
            9. Dispute Resolution
          </h2>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Governing Law:</strong> These Terms are governed by the laws
            of [Your Jurisdiction]. Any disputes arising from these Terms will
            be resolved in the courts of [Your Jurisdiction].
          </p>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Resolution Process:</strong> Any disputes should be resolved
            through negotiation. If a resolution cannot be reached, the dispute
            may be submitted to arbitration or a court of competent
            jurisdiction.
          </p>
          <h2 className="text-xl font-bold text-black my-2.5">
            10. Modifications
          </h2>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Changes to Terms:</strong> We may update these Terms from
            time to time. We will notify you of significant changes by posting
            the revised Terms on the App.
          </p>
          <p className="text-base text-black mb-2.5 leading-6">
            <strong>Effective Date:</strong> The revised Terms will be effective
            as of the date of posting.
          </p>
          <h2 className="text-xl font-bold text-black my-2.5">
            11. Contact Us
          </h2>
          <p className="text-base text-black mb-2.5 leading-6">
            If you have any questions about these Terms or the App, please
            contact us at:
          </p>
          <p className="text-base font-bold text-black">Harshit Foundation</p>
          <div className="flex items-center mb-1">
            <span className="text-black">Email:</span>
            <a
              href="mailto:Harshit@popfeedapp.com"
              className="text-blue-600 hover:underline ml-1"
            >
              Harshit@popfeedapp.com
            </a>
          </div>
          <div className="flex items-center">
            <span className="text-black">Phone:</span>
            <a
              href="tel:+16282229332"
              className="text-blue-600 hover:underline ml-1"
            >
              +16282229332
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
