import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../redux/slice/Profile";
import Footer from "../components/Footer";

function Profile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.profileSlice);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(fetchUser({ userName: id }, { signal }));

    return () => {
      controller.abort();
    };
  }, [dispatch, id]);

  if (loading)
    return (
      <div className="max-w-[640px] mx-auto flex flex-col h-screen px-2 md:px-4 py-4">
        <section className="flex flex-col gap-4 mt-4">
          <div className="size-60 self-center rounded-3xl mt-16 mb-8 bg-[#ffffff1a] animate-pulse" />
          <div className="h-12 bg-[#ffffff1a] rounded animate-pulse mx-auto w-3/4" />
          <div className="h-12 bg-[#ffffff1a] rounded animate-pulse mx-auto w-2/3" />
          <div className="h-6 bg-[#ffffff1a] rounded animate-pulse mx-auto w-1/2 mt-4" />
        </section>
        <div className="mt-8 self-center px-8 py-2 rounded-full flex gap-3 items-center bg-[#ffffff1a] animate-pulse h-16 w-64" />
      </div>
    );

  if (error)
    return (
      <div className="max-w-[640px] mx-auto flex flex-col h-screen px-2 md:px-4 py-4 items-center justify-center">
        <div className="bg-[#00000080] rounded-xl p-6 max-w-[500px] w-full">
          <h2 className="text-xl font-semibold text-white mb-4">Error</h2>
          <pre className="text-red-400 whitespace-pre-wrap break-words">
            {JSON.stringify(error, null, 2)}
          </pre>
        </div>
      </div>
    );

  return (
    <div className="max-w-[640px] mx-auto flex flex-col h-screen px-2 md:px-4 py-4">
      <section className=" flex flex-col gap-4 mt-4">
        <img
          src="/images/Meta/logo.png"
          className="size-60 self-center rounded-3xl mt-16 mb-8"
        />
        <p className="text-4xl text-center font-bold">
          you were invited
          <br />
          by @{data?.username}
        </p>
        <p className="font-semibold text-center">
          See AI TikToks about friends daily
        </p>
      </section>
      <Footer />
    </div>
  );
}

export default Profile;
