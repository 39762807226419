import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserInfo } from '../../firebase/getUserInfo';

const initialState = {
  isAuthenticated: false,
  userInfo: null,
  loading: false,
  error: null,
};

export const fetchUserInfo = createAsyncThunk(
  'fetchUserInfo',
  async (_, { rejectWithValue }) => {
    try {
      const userInfo = await getUserInfo();
      return userInfo;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
    });
    builder.addCase(fetchUserInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default authSlice.reducer;
