import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function CompletionPage() {
  const currentSubscription = useSelector(
    (state) => state.subscription.currentSubscription
  );
  console.log(currentSubscription)

  if (!currentSubscription) {
    return <Navigate to="/product" replace />;
  }


  return (
    <main className="flex flex-col text-black  items-center bg-white w-screen min-h-screen overflow-y-auto scrollbar-sleek p-8">
      <h1 className="text-3xl font-bold mb-6 text-center">
        Thank You for Your Purchase! 🎉
      </h1>
      <div className="bg-gray-100 rounded-lg p-6 max-w-md w-full">
        <img
          src={currentSubscription.image}
          alt={currentSubscription.name}
          className=" object-cover rounded-lg mb-4"
        />
        <h2 className="text-2xl font-semibold mb-2">{currentSubscription.name}</h2>
        <p className="text-gray-600 mb-2">{currentSubscription.description}</p>
        <p className="text-lg font-bold">${currentSubscription.price.toFixed(2)}</p>
      </div>
      <p className="mt-6 text-center text-gray-600">
        We appreciate your business. Your order has been confirmed and will be processed shortly.
      </p>
    </main>
  );
}

export default CompletionPage;
