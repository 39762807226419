import React from "react";
import { Helmet } from "react-helmet";

const RouteMeta = ({ tags }) => {
  return (
    <Helmet>
      {tags.map((item, index) => {
        return <meta key={index} {...item} />;
      })}
    </Helmet>
  );
};

export default RouteMeta;
