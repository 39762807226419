import React, { useEffect, useRef, useState } from "react";
import { Heart, Zap, Users, Lock } from "lucide-react";
import Header from "../components/Layout/NavBar";
import ProductCard from "../components/UI/ProductCard";
import ReasonCard from "../components/UI/ReasonCard";
import { Volume2, VolumeX  } from "lucide-react";
const products = [
  {
    id: 1,
    name: "Pookie Kitten",
    description: "Playful feline friend for your home.",
    price: 9.99,
    image: "/images/products/pookie_kitten.jpg",
  },
  {
    id: 2,
    name: "Pookie Puppy",
    description: "Adorable companion for your family.",
    price: 19.99,
    image: "/images/products/pookie_puppy.jpg",
  },
  {
    id: 3,
    name: "Pookie Exotic",
    description: "Unique pets for adventurous owners.",
    price: 29.99,
    image: "/images/products/pookie_exotic.jpg",
  },
];

const reasons = [
  {
    icon: Heart,
    title: "AI-powered personalization",
    description:
      "Our app learns your pet's preferences and behaviors, providing tailored recommendations for care, nutrition, and playtime.",
  },
  {
    icon: Zap,
    title: "Daily pet content generation",
    description:
      "Receive cute photos, funny videos, and interesting facts about your pet breed every day, keeping you entertained and informed.",
  },
  {
    icon: Users,
    title: "Connect with other pet owners",
    description:
      "Join a community of pet lovers, share experiences, and arrange playdates for your furry friends in your local area.",
  },
  {
    icon: Lock,
    title: "Secure health tracking",
    description:
      "Safely store and monitor your pet's health records, vaccination schedules, and vet appointments with end-to-end encryption.",
  },
];

function Product() {
  const videoContainerRef = useRef(null);
  const videoRef = useRef(null);
  const mainRef = useRef(null);
  const volumeButtonRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [muted, setMuted] = useState(true);

  const handleScroll = () => {
    const scrollPosition = mainRef.current.scrollTop;
    const videoContainer = videoContainerRef.current;
    const video = videoRef.current;
    const volumeButton = volumeButtonRef.current;

    if (!videoContainer || !video) {
      return;
    }
    const maxScroll = 500;
    const scrollPercentage = Math.min(scrollPosition / maxScroll, 1);
    const maxMargin = 48;
    const margin = scrollPercentage * maxMargin;

    videoContainer.style.paddingLeft = `${margin}px`;
    videoContainer.style.paddingRight = `${margin}px`;
    video.style.borderRadius = `${margin}px`;
    volumeButton.style.marginRight = `${margin}px`;
  };

  return (
    <main
      ref={mainRef}
      onScroll={handleScroll}
      className="bg-white min-h-[100dvh] text-black flex flex-col overflow-auto scrollbar-sleek"
    >
      <Header />

      <div
        ref={videoContainerRef}
        className="box-border max-w-full mb-28 max-h-[900px] relative"
      >
        <video
          ref={videoRef}
          // src="/video/product_showcase_full.mp4"
          src="/video/pookie_toy_demo.mp4"
          autoPlay
          muted={muted}
          loop
          onLoadedMetadata={() => setVideoLoaded(true)}
          playsInline
          controls={false}
          className="transition-all duration-300 ease-out w-full otline-none h-full object-cover"
        />
        {videoLoaded ? (
          <button
            ref={volumeButtonRef}
            onClick={() => setMuted(!muted)}
            className="bg-[#ffffff9d] rounded-full p-2 md:p-4 absolute top-10 right-10"
          >
            {muted ? <VolumeX  size={15} /> : <Volume2 size={15} />}
          </button>
        ) : null}
      </div>
      <section className="mx-auto px-12 py-8 w-full max-w-7xl">
        <h2 className="text-[32px] md:text-[40px] lg:text-[50px] font-bold my-10 sm:my-12 md:my-14 lg:my-16">
          Choose Your Pookie Pet
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 py-6 rounded-lg">
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </section>
      <section className="mx-auto px-12 py-8 w-full max-w-7xl ">
        <h2 className="text-[32px] md:text-[40px] lg:text-[50px] font-bold mb-8">
          Why Choose Pookie Pets?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {reasons.map((reason, index) => (
            <ReasonCard key={index} reason={reason} index={index} />
          ))}
        </div>
      </section>

      <footer className="bg-gray-200 py-6 mt-16">
        <div className="container mx-auto px-4 text-center">
          <p className="text-gray-600">
            © 2024 Harshit Foundation. All rights reserved.
          </p>
        </div>
      </footer>
    </main>
  );
}

export default Product;
