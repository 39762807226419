import RouteMeta from "../components/Meta";
import {getRouteMetaTags} from '../utils/routeMetaTags';
import Footer from "../components/Footer";
import React from "react";
function Home() {
  const metaTags = getRouteMetaTags("/")
  return (
    <React.Fragment>

    <div className="max-w-[640px] mx-auto flex flex-col h-screen px-2 md:px-4 py-4">
       <section className=" flex flex-col gap-4 mt-4">
        <img src="/images/Meta/logo.png" className="size-60 self-center rounded-3xl mt-16 mb-8" />
        <p className="font-semibold text-center text-3xl">
          See AI TikToks about<br />your friends daily.
        </p>
      </section>
      <Footer copyright/>
    </div>
    <RouteMeta tags={metaTags}/>
    </React.Fragment>
  );
}

export default Home;
