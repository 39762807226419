import React from "react";
const PrivacyPolicy = () => {
  return (
    <div className="bg-white min-h-[100dvh] overflow-auto">
      <div className="container mx-auto px-4 py-8">
        <div className="mx-auto">
          <h1 className="text-3xl flex gap-4 items-center font-bold text-black mb-6">
            <img src="images/Meta/logo.png" className="size-12 rounded-lg" />
            Privacy Policy
          </h1>
          <p className="text-base text-black mb-4">
            Welcome to Pookie, operated by Harshit Foundation ("we," "us," or
            "our"). This Privacy Policy explains how we collect, use, disclose,
            and safeguard your information when you use our app ("App"). By
            using the App, you agree to the terms of this Privacy Policy. If you
            do not agree with these terms, please do not use the App.
          </p>
          <h2 className="text-2xl font-bold text-black mt-6 mb-3">
            1. Information We Collect
          </h2>
          <p className="text-base text-black mb-4">
            <span className="font-bold">Personal Information:</span>
            <br />
            Account Information: When you create an account, we collect your
            name, phone number, and email address.
            <br />
            Usage Data: We collect information about your interactions with the
            App, including content you approve or reject, and other user
            activity data.
          </p>
          <h2 className="text-2xl font-bold text-black mt-6 mb-3">
            2. How We Use Your Information
          </h2>
          <p className="text-base text-black mb-2">
            We use the information we collect for various purposes, including:
          </p>
          <p className="text-base text-black mb-4">
            <span className="font-bold">To Provide and Maintain the App:</span>{" "}
            To ensure the App functions correctly and to provide personalized
            content.
            <br />
            <span className="font-bold">To Communicate with You:</span> To send
            you updates, notifications, and other information related to your
            use of the App.
            <br />
            <span className="font-bold">To Improve the App:</span> To understand
            user behavior and preferences to enhance the App's functionality and
            user experience.
            <br />
            <span className="font-bold">To Ensure Security:</span> To monitor
            and prevent fraudulent activities and ensure the security of the
            App.
          </p>
          <h2 className="text-2xl font-bold text-black mt-6 mb-3">
            3. Data Sharing and Disclosure
          </h2>
          <p className="text-base text-black mb-2">
            We do not share your personal information with third parties except
            in the following cases:
          </p>
          <p className="text-base text-black mb-4">
            <span className="font-bold">Service Providers:</span> We may share
            information with trusted service providers who assist us in
            operating the App and providing services to you, subject to
            confidentiality agreements.
            <br />
            <span className="font-bold">Legal Requirements:</span> We may
            disclose your information if required by law or in response to valid
            legal requests from public authorities.
            <br />
            <span className="font-bold">Business Transfers:</span> In the event
            of a merger, acquisition, or sale of assets, your information may be
            transferred as part of the transaction.
          </p>
          <h2 className="text-2xl font-bold text-black mt-6 mb-3">
            4. Data Security
          </h2>
          <p className="text-base text-black mb-4">
            We implement reasonable security measures to protect your
            information. However, no system can be entirely secure, and we
            cannot guarantee the absolute security of your information.
          </p>
          <h2 className="text-2xl font-bold text-black mt-6 mb-3">
            5. Data Retention
          </h2>
          <p className="text-base text-black mb-4">
            We retain your personal information for as long as necessary to
            provide you with the App's services and for legitimate business
            purposes. We will delete or anonymize your information upon request,
            except where we are required to retain it for legal purposes.
          </p>
          <h2 className="text-2xl font-bold text-black mt-6 mb-3">
            6. Your Rights and Choices
          </h2>
          <p className="text-base text-black mb-4">
            <span className="font-bold">Access and Correction:</span> You can
            access and update your personal information through your account
            settings.
            <br />
            <span className="font-bold">Deletion:</span> You can request the
            deletion of your account and personal information by contacting us.
            <br />
            <span className="font-bold">Opt-Out:</span> You can opt out of
            receiving promotional communications from us by following the
            unsubscribe instructions in those communications.
          </p>
          <h2 className="text-2xl font-bold text-black mt-6 mb-3">
            7. Children's Privacy
          </h2>
          <p className="text-base text-black mb-4">
            The App is not intended for children under the age of 13. We do not
            knowingly collect personal information from children under 13. If we
            become aware that we have inadvertently collected such information,
            we will take steps to delete it.
          </p>
          <h2 className="text-2xl font-bold text-black mt-6 mb-3">
            8. International Data Transfers
          </h2>
          <p className="text-base text-black mb-4">
            Your information may be transferred to and processed in countries
            other than your own. We ensure that appropriate safeguards are in
            place to protect your information during such transfers.
          </p>
          <h2 className="text-2xl font-bold text-black mt-6 mb-3">
            9. Changes to This Privacy Policy
          </h2>
          <p className="text-base text-black mb-4">
            We may update this Privacy Policy from time to time. We will notify
            you of significant changes by posting the revised policy on the App.
            The revised policy will be effective as of the date of posting.
          </p>
          <h2 className="text-2xl font-bold text-black mt-6 mb-3">
            10. Contact Us
          </h2>
          <p className="text-base text-black mb-2">
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at:
          </p>
          <p className="font-bold text-black mb-2">Harshit Foundation</p>
          <div className="flex items-center mb-1">
            <span className="text-black">Email:</span>
            <a
              href="mailto:Harshit@popfeedapp.com"
              className="text-blue-600 hover:underline ml-1"
            >
              Harshit@popfeedapp.com
            </a>
          </div>
          <div className="flex items-center">
            <span className="text-black">Phone:</span>
            <a
              href="tel:+16282229332"
              className="text-blue-600 hover:underline ml-1"
            >
              +16282229332
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
