import React from "react";

function Footer({copyright}) {
  return (
    <div className="p-4 flex flex-col items-center gap-3">
      <a target="_blank" href="https://apps.apple.com/us/app/pookie-meme-your-pookie-daily/id6466747851" className="mt-8 self-center px-8 py-2 rounded-full flex gap-3 items-center bg-[#272727]">
        <img src={"/svg/apple.svg"} className="h-10"/>
        <div className="flex flex-col">
          <span className="text-sm">Download on the</span>
          <span className="font-semibold text-2xl">App Store</span>
        </div>
      </a>
      {copyright ? 
      <span className="text-gray-300 font-semibold text-base">
        Copyright © 2024, Harshit Foundation
      </span>
      : null}
    </div>
  );
}

export default Footer;
