import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CheckoutForm from "../components/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import { stripeApi } from "../utils/stripeApi";

const Order = () => {
  const currentSubscription = useSelector(
    (state) => state.subscription.currentSubscription
  );
  const stripePromise = loadStripe(
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV
      : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  return (
    <div className="min-h-[100dvh] overflow-y-auto bg-white text-black flex flex-col items-center  p-6">
      <h1 className="text-3xl font-bold mb-8">Get You pookie now!!</h1>
      {currentSubscription && stripePromise ? (
        <Elements stripe={stripePromise}>
          <CheckoutForm
            product={currentSubscription}
            price={currentSubscription.price}
          />
        </Elements>
      ) : (
        <div className="text-center">
          <p className="text-gray-600 mb-4">
            No product selected for subscription
          </p>
          <Link
            to="/product"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Choose a Product
          </Link>
        </div>
      )}
    </div>
  );
};

export default Order;
