import axios from "axios";

const instance = axios.create({
  baseURL: "https://srhe29trb2.execute-api.us-east-2.amazonaws.com/dev",
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
  },
});

export const api = instance;
