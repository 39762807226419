const routeMetaTags = {
  "/": [],
  "/feed/:id": [],
  "/profile/:id": [],
};

// Alternatively, a function to determine meta tags dynamically:

export const getRouteMetaTags = (route) => {
  switch (route) {
    case "/":
      return routeMetaTags["/"];
    case "/feed/:id":
      return routeMetaTags["/feed/:id"];
    case "/profile/:id":
      return routeMetaTags["/profile/:id"];
    default:
      return {};
  }
};
