import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logAnalyticsEvent } from "../utils/analytics";
import { track } from "../utils/traking";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

function ShakingButton({ sentFrom }) {
  const [isShaking, setIsShaking] = useState(false);
  function storeAnalytics() {
    let param;
    switch (sentFrom) {
      case "any":
        param = { from_page_type: "anonymous messages" };
        break;
      case "conf":
        param = { from_page_type: "confession page" };
        break;
      case "tbh":
        param = { from_page_type: "honest reviews" };
        break;
      case "dond":
        param = { from_page_type: "deal or no deal" };
        break;
      case "abtme":
        param = { from_page_type: "about me" };
        break;
      case "wsp":
        param = { from_page_type: "whisper secret" };
        break;
      default:
        return;
    }

    logAnalyticsEvent("analytics/download_button_click", param);
    track("tracking/download_button_click", param);
  }
  useEffect(() => {
    const shakeInterval = setInterval(() => {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 820);
    }, 1820);
    return () => clearInterval(shakeInterval);
  }, []);

  return (
    <button
      className={`bg-black text-white mt-5 font-semibold md:text-xl text-base self-stretch w-full py-5 rounded-full ${
        isShaking ? "animate-shake" : ""
      }`}
    >
      <a
        href="https://apps.apple.com/us/app/pookie-meme-your-pookie-daily/id6466747851"
        target="_blank"
        onClick={storeAnalytics}
      >
        Get your own Pookie names
      </a>
    </button>
  );
}

function UserCounter({sentFrom}) {
	const [count, setCount] = useState(Math.floor(Math.random() * 200) + 100);
	const [phase, setPhase] = useState('initial');
  
	useEffect(() => {
	  const interval = setInterval(() => {
		let increment;
		if (phase === 'initial') {
		  increment = Math.random() < 0.4 ? -1 : Math.floor(Math.random() * 3);
		} else {
		  increment = Math.floor(Math.random() * 3) + 1;
		}
		
		setCount((prevCount) => prevCount + increment);
	  }, 1000);
  
	  const phaseTimeout = setTimeout(() => {
		setPhase('incremental');
	  }, 10000); // Switch to incremental phase after 10 seconds
  
	  return () => {
		clearInterval(interval);
		clearTimeout(phaseTimeout);
	  };
	}, [phase]);
  
	return (
	  <div className="w-full max-w-3xl flex flex-col">
		<p className="text-center font-bold">
		  👇{count} people just tapped the button👇
		</p>
		<ShakingButton sentFrom={sentFrom}/>
	  </div>
	);
  }

function NGLSent({ color1, color2, sentFrom }) {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  useEffect(() => {
    let page_type;
    switch (sentFrom) {
      case "any":
        page_type = "anonymous messages";
        break;
      case "conf":
        page_type = "confession page";
        break;
      case "tbh":
        page_type = "honest reviews";
        break;
      case "dond":
        page_type = "deal or no deal";
        break;
      case "abtme":
        page_type = "about me";
        break;
      case "wsp":
        page_type = "whisper secret";
        break;
      default:
        return;
    }
    logAnalyticsEvent("analytics/confirmation_page_view", {
      from_page_type: page_type
    });
    track("tracking/confirmation_page_view", {
      from_page_type: page_type
    });
  }, [pathname]);

  return (
    <main
      style={{
        background: `linear-gradient(to bottom right, ${color1}, ${color2})`,
      }}
      className="h-screen flex flex-col items-center justify-between "
    >
      <div className="max-w-3xl w-full mt-8 px-4 flex flex-col">
        <div className="top-4 left-4 text-white">
          <ChevronLeft
            size={36}
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          />
        </div>

        <FontAwesomeIcon icon={faCircleCheck} className="text-[120px] mt-6" />
        <p className="font-bold text-xl md:text-2xl mt-2 md:mt-4 mb-8 self-center">
          Sent!
        </p>

        <UserCounter sentFrom={sentFrom}/>

        <p
          onClick={() => navigate(-1)}
          className="cursor-pointer underline text-base md:text-xl mt-4 font-semibold self-center"
        >
          Send another message
        </p>
      </div>
    </main>
  );
}

export default NGLSent;
