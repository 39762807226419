import { useRef, useState, useEffect } from "react";
import { Pause, Play, Share, Send } from "lucide-react";
import Hls from 'hls.js';

function FeedComponents({ convertedVideoURL, userName }) {
  const videoRef = useRef();
  const [showButton, setShowButton] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = () => {
    if (!videoRef.current) return;

    videoRef.current.paused
      ? videoRef.current.play()
      : videoRef.current.pause();
    setShowButton(true);
    setTimeout(() => {
      setShowButton(false);
    }, 1000);
  };

  useEffect(() => {
    if (!convertedVideoURL) return;

    const handleError = () => {
      setError('Failed to load video'); // Set a more specific error message if needed
    };

    // Check if the video URL is m3u8 format
    if (convertedVideoURL.endsWith('.m3u8')) {
      const hls = new Hls();
      hls.loadSource(convertedVideoURL);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        hls.attachMedia(videoRef.current);
        videoRef.current.play();
      });
      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          handleError();
        }
      });

      return () => {
        hls.destroy();
      };
    } else {
      // If not m3u8, directly use the video URL
      videoRef.current.src = convertedVideoURL;
    }

  }, [convertedVideoURL]);

  useEffect(() => {
    if (showButton) {
      const timeout = setTimeout(() => {
        setShowButton(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [showButton]);

  return (
    <div className="h-[95%] bg-black rounded-3xl overflow-hidden relative cursor-pointer">
      <video
        ref={videoRef}
        src={`convertedVideoURL`}
        className="h-full w-full object-contain"
        controls={false}
      >
        <source src={convertedVideoURL} />
      </video>

      {/* pause/play indicator */}
      <div
        onClick={handleClick}
        className="group: w-full h-[75%] absolute bottom-1/2 translate-y-1/2"
      >
        <div
          className={`absolute top-[50%] left-[50%] bg-[#00000060] text-white translate-x-[-50%] translate-y-[-50%] p-6 rounded-full transition-opacity duration-300 ${
            showButton ? "opacity-100" : "opacity-0"
          }`}
        >
          {videoRef.current?.paused ? <Play /> : <Pause />}
        </div>
      </div>
{/* 
      <div className="absolute text-xl text-white items-center font-semibold flex gap-1 bottom-4 right-4">
        <img src="logo.png" className="size-8" />
        Pookie
      </div> */}

      {/* username */}
      <div className="absolute top-5 right-1/2 translate-x-1/2 w-full px-4">
        <div className="bg-[#00000090] text-white px-3 py-3 rounded-full font-semibold flex items-center gap-2">
          <img
            src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            className="size-10 rounded-full"
          />
          <span>{userName}</span>
        </div>
      </div>
    </div>
  );
}

export default FeedComponents;
