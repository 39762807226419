import { configureStore } from "@reduxjs/toolkit";
import FeedReducer from './slice/feed';
import ProfileReducer from './slice/Profile';
import AuthReducer from "./slice/Auth";
import Subscription from "./slice/Subscription";
export const store = configureStore({
    reducer : {
        feedSlice : FeedReducer,
        profileSlice : ProfileReducer,
        Auth: AuthReducer,
        subscription : Subscription
    }
})